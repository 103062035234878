
import { defineComponent, ref, onMounted, onBeforeMount, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addPosModal from "@/views/influencer-platform/social-connections/AddSocialConnectionModal.vue";
import FilterDropdwon from "@/views/influencer-platform/social-connections/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSocialConnections, ApiBase } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface Channel {
  id: number;
  account_number: string;
  verification_status: string;
  type: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "social-connections",
  components: {
    MBDatatable,
    FilterDropdwon,
    addPosModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Channel>>([]);
    const checkedPoss = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      // {
      //   name: "ID",
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("influencerAccount.account"),
        key: "account_id",
        sortable: false,
      },
      {
        name: t("influencerAccount.socialConnectionAccount"),
        key: "account_number",
        sortable: false,
      },
      {
        name: t("influencerAccount.socialConnectionVerificationStatus"),
        key: "verification_status",
        sortable: false,
      },
      {
        name: t("influencerAccount.socialConnectionType"),
        key: "type",
        sortable: false,
      },
      {
        name: t("influencerAccount.socialConnectionNotes"),
        key: "notes",
        sortable: false,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
        ortable: false,
      },
    ]);

    const options = ref({
      type: [] as any[],
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSocialConnectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getSocialConnectionList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiSocialConnections.getSocialConnectionList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: "global_social_connection_type",
      });
      if (data.code == 0) {
        options.value.type = data.data.items;
      }
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          if (item == "account_number") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSocialConnectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getSocialConnectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const stageClass = (status) => {
      let statusClass = "";
      switch (status) {
        case 1:
          statusClass = "badge-light-success";
          break;
        case 0:
          statusClass = "badge-light-danger";
          break;
        default:
          break;
      }
      return statusClass;
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSocialConnectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const updateList = () => {
      getSocialConnectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getSocialConnectionList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getSocialConnectionList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getSocialConnectionList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const typeVal = computed(() => {
      return (val) => {
        let obj = options.value.type.find((item) => {
          return item.value == val;
        });
        return obj?.label || val;
      };
    });

    onBeforeMount(() => {
      getDropdownOptions();
    });
    return {
      t,
      formatDate,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      typeVal,
      options,
      checkedPoss,
      getSocialConnectionList,
      stageClass,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      getDropdownOptions,
      updateList,
    };
  },
});
