import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = {
  key: 0,
  type: "button",
  class: "btn btn-light-primary me-3"
}
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = {
  key: 1,
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_pos"
}
const _hoisted_14 = { class: "svg-icon svg-icon-2" }
const _hoisted_15 = { class: "card-body pt-0" }
const _hoisted_16 = { class: "symbol symbol-circle symbol-45px w-45px h-45px d-flex justify-content-center align-items-start overflow-hidden me-2" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  class: "w-100 h-auto",
  src: "media/avatars/blank.png",
  alt: ""
}
const _hoisted_19 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_20 = {
  href: "#",
  class: "fw-bolder"
}
const _hoisted_21 = { class: "text-muted fw-bold d-block text-hover-primary fs-7" }
const _hoisted_22 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdwon = _resolveComponent("FilterDropdwon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_addPosModal = _resolveComponent("addPosModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
              style: {"cursor":"pointer"},
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, _ctx.search != '']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.checkedPoss.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
                ]),
                _createVNode(_component_FilterDropdwon, {
                  onSubmitFilter: _ctx.handleFilter,
                  onResetFilter: _ctx.handleFilterReset
                }, null, 8, ["onSubmitFilter", "onResetFilter"]),
                (0)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr078.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("common.export")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (0)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("influencerAccount.addSocialConnection")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          order: "desc",
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-account_id": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: 
              item.__show.stage == 'account'
                ? '/influencer-platform/influencer-accounts/' +
                  item.account_id +
                  '/overview'
                : '/interactive-marketing/account-conversions/' +
                  item.account_id +
                  '/overview'
            ,
              class: "text-gray-800 text-hover-primary mb-1 d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  (item.__show.photo)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "w-100 h-auto",
                        src: item.__show.photo,
                        alt: ""
                      }, null, 8, _hoisted_17))
                    : (_openBlock(), _createElementBlock("img", _hoisted_18))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(item.__show.account_name), 1),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(item.__show.mobile), 1)
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "cell-account_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.account_number), 1)
          ]),
          "cell-type": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.typeVal(item.type)), 1)
          ]),
          "cell-verification_status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.stageClass(item.verification_status)])
            }, _toDisplayString(item.verification_status == 1
              ? _ctx.t("influencerAccount.verified")
              : _ctx.t("influencerAccount.unverified")), 3)
          ]),
          "cell-notes": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.notes), 1)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: {
              name: 'social-connections-information',
              params: {
                id: item.id,
              },
            },
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_22, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    (0)
      ? (_openBlock(), _createBlock(_component_addPosModal, {
          key: 0,
          onUpdateList: _ctx.updateList
        }, null, 8, ["onUpdateList"]))
      : _createCommentVNode("", true)
  ], 64))
}